<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import DepartamentsModel from '@/modules/departamnets/departaments-model';
import GroupCard from '@/shared/cards/GroupCard.vue';
import AddFaculty from '@/shared/components/AddFaculty.vue';
import i18n from '../../../shared/plugins/vue-i18n';

const { fields } = DepartamentsModel;

const store = {
  state: {
    branches: [],
    branchesIds: [],
    depId: 0,
    depInfo: {},
    depName: '',
    upload: false,
    parentName: '',
    parentId: 0,
    parentType: '',
    optionsAdmins: [],
  },
};

export default {
  name: 'ManagementGroupPage',
  components: {
    GroupCard,
    AddFaculty,
  },
  data() {
    return {
      store,
      fields,
      role: window.localStorage.getItem('role'),
      model: {},
    };
  },
  methods: {
    ListBranches() {
      store.state.optionsFaculties = [];
      store.state.branches = [];
      store.state.branchesIds = [];
      const listDepPath = '/WebListBranches.php';
      const data = new FormData();
      data.append('parent_id', parseInt(this.$route.params.parent_id, 10));
      data.append('parent_type', 'department');
      data.append('levels', 2);

      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }

      fetch(server + listDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.branches && json.branches.length) {
            store.state.branches = json.branches;
            json.branches.forEach((branch, i) => {
              store.state.branchesIds[i] = branch.id;
            });
            store.state.upload = true;
          } else {
            store.state.upload = true;
          }
        });
    },
    reorderBranches() {
      const reorderPath = '/WebReorderBranches.php';
      const data = new FormData();

      store.state.branchesIds.forEach((id) => {
        data.append('branches[]', id);
      });

      fetch(server + reorderPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          this.ListBranches();
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
          }
        });
    },
    openAddFaculty() {
      const addFacultyWindow = document.querySelector('.add-item');
      addFacultyWindow.classList.toggle('show');
      if (addFacultyWindow.classList.value.includes('hide')) {
        addFacultyWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addFacultyWindow.classList.toggle('hide');
        }, 300);
      }
      this.ListBranches();
    },
    getDepartament() {
      const getDepPath = '/WebGetBranchDetails.php';

      const data = new FormData();
      data.append('id', parseInt(this.$route.params.parent_id, 10));
      data.append('type', 'department');
      fetch(server + getDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else {
            store.state.depName = json.name;
            if (json.parent_name) {
              store.state.parentName = json.parent_name;
              store.state.parentId = json.parent_id;
              store.state.parentType = json.type;
            } else {
              store.state.parentName = '';
            }
          }
        });
    },
    editDep(dep) {
      setTimeout(() => {
        store.state.depId = dep.id ? dep.id : 0;
        store.state.depInfo = dep !== 0 ? dep : {};
      }, 300);
      this.openAddFaculty();
    },
  },
  beforeMount() {
    this.ListBranches();
    if (parseInt(this.$route.params.parent_id, 10) !== 0) {
      this.getDepartament();
    }
  },
};
</script>
<template>
  <div>
    <b-row style="margin-bottom: 24px">
      <b-col class="basic-1 title-0-medium-28 p-0" xl="3" lg="3" md="5" sm="12">
        {{ $t("DEPARTAMENTS.LIST_GROUP") }}
      </b-col>
      <b-col class="basic-3 subhead-reg-14"
             style="margin: 6px 0" xl="6" lg="6" md="5" sm="12">

        <unicon name="estate" width="16" height="16" fill="#1694D0"
                style="cursor: pointer;margin-top: -12px"
                v-on:click="role.includes('admin')  ?$router.push('/management/department/0') : ''"
                v-if="store.state.parentName !== '' || store.state.depName !== ''"></unicon>
        <unicon name="angle-right" width="20" height="20" fill="#828282"
                 v-if="store.state.parentName !== ''"/>
        <div class="d-inline"  v-if="store.state.parentName"
             style="cursor: pointer;color: #1694D0;min-width: max-content"
             v-on:click="$router.push(
               `/management/${store.state.parentType}/${store.state.parentId}`)">
          {{store.state.parentName}}
        </div>

        <unicon name="angle-right" width="20" height="20" fill="#828282"
                v-if="store.state.depName !== ''" />
        <div class="d-inline">
          {{store.state.depName}}
        </div>
      </b-col>
      <b-col class="add_news_button p-0"  xl="3" lg="3" md="2" sm="12">
        <div class="btn btn-primary float-end" v-on:click="editDep(0);openAddFaculty"
             v-if="store.state.branches.length !== 0">
          <unicon name="plus" width="16" height="16"
                  style="margin-right: 12px;margin-top: -2px" fill="white"/>
          {{ $t('DEPARTAMENTS.ADD_GROUP') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-if="store.state.branches.length !== 0">
      <b-col md="12" class="p-0"
             v-for="(item,i) in store.state.branches" :key="i">
        <GroupCard
          :dep-info="item"
          :index="i"
          :callback="editDep"
          :callback-params="item"
        >
        </GroupCard>
      </b-col>
    </b-row>

    <b-row v-if="store.state.branches.length === 0">
      <b-col md="12">
        <div class="new-dep"
             v-on:click="openAddFaculty('group')">
          <unicon name="users-alt" width="24" height="24" fill="#1694D0"
                  style="margin-right: 26px"></unicon>
          <div class="title-2-bold-20 basic-3 mb-0">
            {{ $t('DEPARTAMENTS.ADD_GROUP') }}
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="add-item hide">
      <AddFaculty type="group" :parent-id="parseInt(this.$route.params.parent_id, 10)"
                  :dep-id="store.state.depId"
                  :key="store.state.depId"
                  :dep-info="store.state.depInfo"
                  :callback="ListBranches"
      >
      </AddFaculty>
    </div>

  </div>

</template>
