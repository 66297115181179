<script>
export default {
  name: 'CustomSelect',
  props: {
    msg: String,
    options: {
      type: Array,
    },
    admin: {
      type: Number,
    },
    setAdmin: {
      type: Function,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    select(option) {
      this.selected = option;
      this.setAdmin(option);
    },
  },
  beforeMount() {
    if (this.admin !== 0) {
      for (let i = 0; i < this.options.length; i += 1) {
        if (parseInt(this.options[i].value, 10) === parseInt(this.admin, 10)) {
          this.selected = this.options[i];
        }
      }
    }
  },
};
</script>
<template>
  <div class="custom_select">
    <b-dropdown>
      <div slot="button-content" :class="selected ? 'selected' : 'unselected'">
        <img :src="selected && selected.src ? selected.src : '/media/Frame 751.svg'" />
        <div v-if="selected === null" class="basic-4 headline-regular-16 d-inline">
          {{ $t("GENERAL.NOT_SELECTED") }}
        </div>
        <div v-if="selected !== null" class="d-inline-block">
          <div class="basic-1 headline-regular-16">
            {{selected.text}}
          </div>
          <div class="basic-3 caption-2-reg-12">
            {{selected.email ? selected.email : 'ex@yahoo.com'}}
          </div>
        </div>
      </div>
      <b-dropdown-item
        @click="select(option)"
      >
        <div class="add_new_user">
          <unicon fill="#00CA72" height="18" width="18" name="user-plus"/>
          {{ $t('DEPARTAMENTS.ADD_NEW_USER') }}
        </div>

      </b-dropdown-item>
      <b-dropdown-item
        :disabled="option.disabled"
        @click="select(option)"
        v-for="option in options"
        :key="option.value"
      >
        <div>
          <img :src="option.src ? option.src : '/media/Frame 751.svg'">
          <div class="d-inline-block">
            <div class="basic-1 headline-regular-16">
              {{option.text}}
            </div>
            <div class="basic-3 caption-2-reg-12">
              {{option.email ? option.email : 'ex@yahoo.com'}}
            </div>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<style>

</style>
