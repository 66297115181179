<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddDepartamentsModel from '@/modules/departamnets/add-departaments-model';
import CustomSelect from '@/shared/components/CustomSelect.vue';
import BaseButton from '@/shared/components/BaseButton.vue';

const { fields } = AddDepartamentsModel;
const store = {
  state: {
    optionsAdmins: [],
    update: 0,
    students: [],
    students_success_green: 0,
    students_success_red: 0,
    students_success_yellow: 0,
    role: window.localStorage.getItem('role'),
  },
};
export default {
  name: 'AddFaculty',
  components: {
    CustomSelect,
    BaseButton,
  },
  props: {
    depId: {
      type: Number,
      required: true,
      default: 0,
    },
    depInfo: {
      type: Object,
    },
    parentId: {
      type: Number,
      required: true,
      default: 0,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      files: [],
      error: '',
      Img: [],
      OldImg: [],
      model: {},
      departId: this.depId,
    };
  },
  methods: {
    openAddFaculty() {
      const addFacultyWindow = document.querySelector('.add-item');
      addFacultyWindow.classList.toggle('show');
      if (addFacultyWindow.classList.value.includes('hide')) {
        addFacultyWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addFacultyWindow.classList.toggle('hide');
        }, 300);
      }
    },
    ListAdmins() {
      store.state.optionsAdmins = [];
      const listAdminsPath = '/WebSuggestAdministrators.php';
      fetch(server + listAdminsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.users && json.users.length) {
            json.users.forEach((user, i) => {
              store.state.optionsAdmins[i] = {
                value: user.id,
                text: user.name,
                src: user.image,
                email: user.email,
              };
            });
            store.state.update += 1;
          }
        });
    },
    addDepartament() {
      this.openAddFaculty();
      const data = new FormData();
      let addDepPath = '';
      if (parseInt(this.depId, 10) !== 0) {
        addDepPath = '/WebEditBranch.php';
        data.append('id', this.depId);
      } else {
        addDepPath = '/WebAddBranch.php';
      }

      data.append('parent_id', this.parentId);
      data.append('type', this.type);
      if (this.model.admin !== undefined) {
        data.append('administrator_id', this.model.admin.value);
      }
      if (this.model.title !== undefined) {
        data.append('name', this.model.title);
      }
      data.append('logo', this.Img[0] ? this.Img[0] : '');
      fetch(server + addDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            this.callback();
          }
        });
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.Img = values;
        this.OldImg = [];
      });
    },
    getDepartament() {
      const getDepPath = '/WebGetBranchDetails.php';

      const data = new FormData();
      data.append('id', parseInt(this.depId, 10));
      data.append('type', this.type);
      fetch(server + getDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else {
            this.model.faculty = json.parent_id;
            this.model.title = json.name;
            this.OldImg = json.logo ? [json.logo] : [];
            if (json.administrator_id !== null) {
              this.model.admin = json.administrator_id;
            }
            store.state.students = json.students_list;

            store.state.students_success_green = json.students_success_green;
            store.state.students_success_red = json.students_success_red;
            store.state.students_success_yellow = json.students_success_yellow;
          }
        });
    },
    deleteDepartament() {
      this.openAddFaculty();
      const data = new FormData();
      const deleteDepPath = '/WebRemoveBranch.php';

      data.append('id', this.depId);
      data.append('type', this.type);
      fetch(server + deleteDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            this.callback();
          }
        });
    },
    setAdmin(admin) {
      this.model.admin = admin;
    },
  },
  beforeMount() {
    this.ListAdmins();
    if (this.depId !== 0) {
      this.getDepartament();
    } else {
      this.model.faculty = this.parentId;
      this.model.title = '';
      this.OldImg = [];
      this.model.admin = '';
    }
  },
};
</script>
<template>
  <div class="add-item" :key="store.state.optionsAdmins.length">
    <div class="add-item-left" v-on:click="openAddFaculty">
    </div>
    <div class="add-item-window">
      <form class="form" v-on:submit.prevent="addDepartament(1)" id="add_dep_form">

        <div style="height: 100vh" :key="this.model.name">
          <div class="menu">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddFaculty"/>

            <div class="title" v-if="this.type === 'department'">
              {{ this.depId !== 0 ? this.model.title : $t('DEPARTAMENTS.ADD_DEPARTAMENT') }}
            </div>

            <div class="title" v-if="this.type === 'group'">
              {{ this.depId !== 0 ? this.model.title : $t('DEPARTAMENTS.ADD_GROUP') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit">
              <unicon fill="white" height="16" width="16" name="save"/>
              {{ $t('GENERAL.SAVE') }}
            </BaseButton>
          </div>
          <div class="content">
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.BASIC_INFO') }}
                </div>
              </b-col>
            </b-row>
            <div style="display: inline-flex;width: 100%;padding: 0 24px">
              <div style="margin-right: 24px">
                <div class="upload-photo">
                  <img :src="this.OldImg.length !== 0 ? this.OldImg : this.Img" alt=""
                       v-if="this.Img.length !== 0 || this.OldImg.length !== 0"/>
                  <unicon class="unicon-image" name="image"
                          v-if="this.Img.length === 0 && this.OldImg.length === 0"
                          width="80" height="80" fill="white"/>

                  <div class="upload-photo-over">
                    <input
                      type="file"
                      style="z-index: 1;opacity: 0;position: absolute;bottom: 0;
                      height: 190px;width: 190px;"
                      accept="image/*"
                      ref="uploadInput"
                      @change="previewImgs"
                      multiple
                    />
                    <unicon name="camera" width="40" height="40" fill="white"/>
                  </div>
                </div>

              </div>
              <div style="width: -webkit-fill-available;">
                <div class="form-floating-2">
                  <label for="floatingInput">
                    {{ fields.title.label }}</label>
                  <input type="text"
                         class="form-control-2"
                         :id="fields.title.name"
                         v-model="model[fields.title.name]"
                         :placeholder="fields.title.label"
                         required
                         maxlength="200"
                  >
                </div>
              </div>
            </div>
            <div class="form-separator">

            </div>

            <b-row v-if="store.state.role.includes('admin')">
              <b-col class="col" md="12">
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.MANAGEMENT') }}
                </div>
              </b-col>
              <b-col class="col" md="12" :key="store.state.update">
                <CustomSelect
                  v-if="store.state.optionsAdmins.length > 0"
                  :key="store.state.optionsAdmins.length"
                  :options="store.state.optionsAdmins"
                  :set-admin="setAdmin"
                  :admin="this.depId !== 0  ? depInfo.administrator_id : 0">
                </CustomSelect>
              </b-col>
            </b-row>
            <b-row>
              <div class="form-separator" v-if="depId !== 0 && store.state.students.length > 0">
              </div>
            </b-row>

            <b-row v-if="store.state.students.length > 0">
              <b-col class="col" md="12">
                <div class="title-2-bold-20 basic-2">
                  {{ $t('DEPARTAMENTS.STUDENTS') }}
                </div>
              </b-col>
              <b-col class="col" md="4" :key="store.state.update">
                <div class="students">
                  <img v-for="(item,i) in store.state.students "
                       :class="'d-inline-block img-' + i" :key="i"
                       :src="item.image !== null ? item.image : '/media/user.jpg'" alt=""/>
                  <div v-if="store.state.students.length > 3"
                       class="students_number basic-1 headline-regular-16">
                    +{{store.state.students.length - 3}} students</div>
                </div>
              </b-col>
              <b-col md="8">
                  <div class="students_success mt-0" style="margin-bottom: 16px">
                    <div class="position-relative">
                      <div class="basic-2" style="height: fit-content">
                        {{ $t('DEPARTAMENTS.STUDENTS_SUCCESS') }}:
                      </div>
                      <div style="position: absolute;right: 0;top:0;">
                        <label class="success">{{ store.state.students_success_green }}%</label>
                        <label class="warning">{{store.state.students_success_yellow}}%</label>
                        <label class="danger">{{store.state.students_success_red}}%</label>
                      </div>
                    </div>
                    <b-progress :max="100">
                      <b-progress-bar :value="store.state.students_success_green" variant="success">
                      </b-progress-bar>
                      <b-progress-bar :value="store.state.students_success_yellow"
                                      variant="warning">
                      </b-progress-bar>
                      <b-progress-bar :value="store.state.students_success_red" variant="danger">
                      </b-progress-bar>
                    </b-progress>
                  </div>
                  <div class="students_success_right">
                    <div class="float-end">
                      2%
                      <unicon :name="store.state.students_success_red % 2 === 0 ?
                       'arrow-up' :'arrow-down'" style="margin-top: -4px;"
                              :fill="store.state.students_success_red % 2 === 0 ?
                               '#00CA72' : '#E2445C'" height="24" width="24" />
                    </div>
                  </div>
              </b-col>
            </b-row>

            <b-row>
                <b-col class="col" md="12" v-if="depId !== 0">
                  <BaseButton
                    :variant="'danger'"
                    :callback="deleteDepartament"
                    :callback-params="1"
                    style="width: 100%;margin-top: 16px"
                  >
                    <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                    {{ $t('GENERAL.DELETE') }}
                  </BaseButton>
                </b-col>
            </b-row>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>
