<template>
  <div class="container-card-group"
       style="cursor: pointer">
    <div class="dep-image" v-on:click="goTo">
      <img :src="depInfo.logo ? depInfo.logo : '/media/group.jpg'" alt=""/>
      <div class="hover">
      </div>
      <div class="number basic-1 caption-caps-med-12"> {{index + 1}}</div>
    </div>
    <div class="group-info" v-on:click="goTo">
        <div style="display:flex;margin-bottom: 8px">
          <unicon name="users-alt" fill="#00BA69"
                  height="24" width="24"/>
          <div class="title-2-bold-20 basic-1 mb-0"
               style="max-height: 25px;text-overflow: ellipsis;overflow: hidden;
white-space: nowrap;">
            {{ depInfo.name }}
          </div>
        </div>
        <div class="contains">
          <div class="ellipse"></div>
          <div class="basic-2">{{ depInfo.students }} {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
        </div>
    </div>
    <div class="admin" v-on:click="goTo"
         v-if="depInfo.administrator_name"
         style="cursor: pointer">
      <img class="d-inline-block" src="/media/Frame 751.svg" alt=""/>
      <div class="admin_name d-inline-block">
        <div class="headline-regular-16 basic-1">
          {{ depInfo.administrator_name }}
        </div>
        <div class="role basic-2">{{ $t('DEPARTAMENTS.FIELDS.ADMIN') }}</div>
      </div>
    </div>
    <div class="students" v-on:click="goTo">
      <img v-for="(item,i) in depInfo.students_list "
           :class="'d-inline-block img-' + i" :key="i"
           :src="item.image !== null ? item.image : '/media/user.jpg'" alt=""/>
      <div v-if="depInfo.students > 3" class="students_number basic-1 headline-regular-16">
        +{{depInfo.students - 3}} {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
    </div>
    <div class="edit" v-on:click="clickCallback" style="padding: 8px;margin-top: -8px">
      <unicon name="ellipsis-v" fill="#333333"
              height="16" width="16" class="dropdown-news"/>
    </div>
  </div>
</template>

<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'GroupCard',
  props: {
    notRendered: {
      type: Boolean,
      default: false,
    },
    depInfo: {
      type: Object,
    },
    index: {
      type: Number,
      required: false,
    },
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackParams: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
    goTo() {
      this.$router.push(`/management/student/students-results/${this.depInfo.id}`);
    },
  },
};
</script>
