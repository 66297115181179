import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`DEPARTAMENTS.FIELDS.${name.toUpperCase()}`);

const fields = {
  search: new StringField('search', label('search'), {
    required: true,
  }),
};

export default class DepartamentsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
